<template>
  <div class='recognition'>
    <!-- 222 -->
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="动态分析" name="first" v-if="domainName !== '上城区'">
        <h3>动态分析</h3>
        <base-table ref="myTable" :filterList="dynamicFilter" :columns="dynamicColumns" :tableList="dynamicData" @queryForm="dynamicForm"></base-table>
        <!-- </div> -->
        <base-pagination class="basepagination" v-show="dynamicTotal>10" :total="dynamicTotal" :page.sync="dynamicParams.pageNum" :limit.sync="dynamicParams.pageSize" :layout="layout" @pagination="dynamicList"></base-pagination>
      </el-tab-pane>
      <el-tab-pane label="预检分诊" name="third" v-if="domainName === '上城区'">
        <h3>预检分诊</h3>
        <div class="btn">
          <el-button type="primary" @click="changeTab('alarm')">查看告警</el-button>
          <el-button type="success" @click="changeTab('all')">查看全部</el-button>
        </div>
        <!-- <div class="tablebox"> -->
        <!-- <base-table ref="precheckmyTable" :filterList="precheckFilterList" :columns="precheckColumns" :tableList="precheckData" @queryForm="precheckQueryForm"></base-table> -->
        <base-table ref="precheckmyTable" :columns="precheckColumns" :tableList="precheckData" @queryForm="precheckQueryForm" class="precheckmyTable"></base-table>
        <!-- </div> -->
        <base-pagination class="basepagination" v-show="precheckTotal>10" :total="precheckTotal" :page.sync="precheckParams.pageNum" :limit.sync="precheckParams.pageSize" :layout="layout" @pagination="prePage"></base-pagination>
      </el-tab-pane>
      <el-tab-pane :label="domainName !== '上城区'?'行为分析':'医废监管'" name="second">
        <h3>{{domainName !== '上城区'?'行为分析':'医废监管'}}</h3>
        <!-- <div class="tablebox"> -->
        <base-table ref="myTable" :filterList="filterList" :columns="columns" :tableList="behaviorData" @queryForm="queryForm"></base-table>
        <!-- </div> -->
        <base-pagination class="basepagination" v-show="behaviorTotal>10" :total="behaviorTotal" :page.sync="behaviorParams.pageNum" :limit.sync="behaviorParams.pageSize" :layout="layout" @pagination="behaviorList"></base-pagination>
      </el-tab-pane>

    </el-tabs>
    <!-- 工作详情弹框 -->
    <working-hours ref="working" />
    <!-- 视频查看弹框 -->
    <video-diadog ref="video" />
  </div>
</template>

<script>
import { analyseList, imageExist, getPreList } from '@/api'
import { mapGetters } from 'vuex'
// import list from './index.json'
import WorkingHours from './components/workingHours.vue'
import VideoDiadog from './components/videoDiadog.vue'
export default {
  components: { WorkingHours, VideoDiadog },
  data () {
    return {
      layout: 'jumper,prev, pager, next,sizes',
      activeName: 'third',
      // 预检分诊相关参数
      precheckColumns: [
        { attrs: { prop: 'unitName', label: '单位名称', align: 'center', minWidth: '200' } },
        { attrs: { prop: 'roomName', label: '监控位置', minWidth: '120', align: 'center' } },
        // { attrs: { prop: 'algorithmName', label: '所属算法', align: 'center' } },
        {
          attrs: { label: '监测日期', align: 'center', prop: 'gmtCreate' },
          time: 'YYYY-MM-DD'
        },
        { attrs: { prop: 'total', label: '巡检次数', align: 'center' } },
        { attrs: { prop: 'alarmSum', label: '告警数量', align: 'center' } },
        {
          attrs: { label: '最近告警时间', align: 'center', prop: 'lastAlarmTime' },
          statusList: [
            { value: true },
            { value: false, label: '无记录', style: { color: 'red' } },
            { value: undefined, label: '无记录', style: { color: 'red' } }
          ]
        },
        {
          attrs: { label: '告警画面', align: 'center' },
          btns: row => [
            { name: '详情', attrs: { type: 'text' }, listeners: { click: () => this.goAlarm(row) } }
          ]
        }
      ],
      precheckData: [],
      preTotalList: [],
      precheckTotal: 0,
      precheckParams: {
        pageNum: 1,
        pageSize: 10,
      },
      // 动态分析相关参数
      dynamicData: [],
      dynamicTotal: 0,
      dynamicloading: false,
      dynamicParams: {
        pageNum: 1,
        pageSize: 10,
        type: 1
      },
      dynamicColumns: [
        { attrs: { prop: 'siteName', label: '单位名称', align: 'center', minWidth: '200' } },
        { attrs: { prop: 'roomName', label: '监控位置', minWidth: '120', align: 'center' } },
        { attrs: { prop: 'sceneName', label: '所属场景', align: 'center' } },
        {
          attrs: { label: '监测日期', sortable: true, align: 'center', prop: 'date' },
          time: 'YYYY-MM-DD'
        },
        {
          attrs: { label: '工作时长', align: 'center' },
          btns: row => [
            { name: '详情', attrs: { type: 'text' }, listeners: { click: () => this.workingHours(row) } }
          ]
        },
        {
          attrs: { label: '告警画面', align: 'center' },
          btns: row => [
            { name: '图片下载', attrs: { type: 'text' }, class: 'btn_org', listeners: { click: () => this.download(row) }, condition: () => this.url !== 'scdisinfection.terabits.cn', },
            { name: '视频播放', attrs: { type: 'text' }, listeners: { click: () => this.goVideo(row) } }
          ]
        }
      ],
      // 医废相关参数
      behaviorData: [],
      behaviorTotal: 0,
      behaviorloading: false,
      behaviorParams: {
        pageNum: 1,
        pageSize: 10,
        type: 2,
        sceneName: ''
      },
      location: [],
      behaviorTime: [],
      // 当前页码条数
      behaviorLimit: 10,
      options: [],
      selectoptions: [],
      value: '',
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }],

      },
      value2: '',
      columns: [
        { attrs: { prop: 'siteName', label: '单位名称', align: 'center', minWidth: '200' } },
        { attrs: { prop: 'roomName', label: '监控位置', minWidth: '120', align: 'center' } },
        { attrs: { prop: 'algorithmNames', label: '所属算法', align: 'center' } },
        {
          attrs: { label: '监测日期', sortable: true, align: 'center', prop: 'date' },
          time: 'YYYY-MM-DD'
        },
        { attrs: { prop: 'alarmSum', label: '告警数量', align: 'center' } },
        {
          attrs: { label: '最近告警时间', align: 'center', prop: 'gmtLastAlarm' },
          statusList: [
            { value: true },
            { value: false, label: '无记录', style: { color: 'red' } },
            { value: undefined, label: '无记录', style: { color: 'red' } }
          ]
        },
        {
          attrs: { label: '告警画面', align: 'center' },
          btns: row => [
            { name: '详情', attrs: { type: 'text' }, listeners: { click: () => this.goDetail(row) } }
          ]
        }
      ]
    };
  },
  created () {
    this.getList()
  },
  mounted () {

  },
  computed: {
    ...mapGetters(['locationList', 'sceneList', 'token', 'baseUrl', 'url', 'domainName']),
    filterList () {
      const arr = [
        {
          name: '设备位置：',
          type: 'cascader',
          options: this.locationList,
          props: { expandTrigger: 'hover', value: 'id', label: 'name' },
          attrs: {
            style: { width: '250px' },
            size: 'small'
          },
          fieldNames: ['siteid', 'roomid']
        },
        {
          name: '场景：',
          type: 'select',
          options: this.sceneList,
          attrs: {
            size: 'small'
          },
          props: { value: 'name', label: 'name' },
          prop: 'sceneName'
        },
        {
          name: '时间：',
          type: 'date',
          props: { start: 'start', end: 'stop' },
          attrs: { type: "daterange", valueFormat: "yyyy-MM-dd", size: 'small' }
        },
        { name: '查询', type: 'button', attrs: { type: 'primary', size: "small" }, listeners: { click: () => this.$refs.myTable.search() } },
        { name: '重置', type: 'button', attrs: { size: "small" }, listeners: { click: () => this.$refs.myTable.reset() } }
      ]
      return arr
    },
    dynamicFilter () {
      const arr = [
        {
          name: '设备位置：',
          type: 'cascader',
          options: this.locationList,
          props: { expandTrigger: 'hover', value: 'id', label: 'name' },
          attrs: {
            style: { width: '250px' },
            size: 'small'
          },
          fieldNames: ['siteid', 'roomid']
        },
        {
          name: '场景：',
          type: 'select',
          options: this.sceneList,
          attrs: {
            size: 'small'
          },
          props: { value: 'name', label: 'name' },
          prop: 'sceneName'
        },
        {
          name: '时间：',
          type: 'date',
          props: { start: 'start', end: 'stop' },
          attrs: { type: "daterange", valueFormat: "yyyy-MM-dd", size: 'small' }
        },
        { name: '查询', type: 'button', attrs: { type: 'primary', size: "small" }, listeners: { click: () => this.$refs.myTable.search() } },
        { name: '重置', type: 'button', attrs: { size: "small" }, listeners: { click: () => this.$refs.myTable.reset() } }
      ]
      return arr
    },
    precheckFilterList () {
      const arr = [
        {
          name: '设备位置：',
          type: 'cascader',
          options: this.locationList,
          props: { expandTrigger: 'hover', value: 'id', label: 'name' },
          attrs: {
            style: { width: '250px' },
            size: 'small'
          },
          fieldNames: ['siteid', 'roomid']
        },
        {
          name: '场景：',
          type: 'select',
          options: this.sceneList,
          attrs: {
            size: 'small'
          },
          props: { value: 'name', label: 'name' },
          prop: 'sceneName'
        },
        {
          name: '时间：',
          type: 'date',
          props: { start: 'start', end: 'stop' },
          attrs: { type: "daterange", valueFormat: "yyyy-MM-dd", size: 'small' }
        },
        { name: '查询', type: 'button', attrs: { type: 'primary', size: "small" }, listeners: { click: () => this.$refs.myTable.search() } },
        { name: '重置', type: 'button', attrs: { size: "small" }, listeners: { click: () => this.$refs.myTable.reset() } }
      ]
      return arr
    }
  },
  methods: {
    handleClick (tab, event) {
      console.log(tab, event)
    },
    // 初始化数据
    getList () {
      if (this.domainName === '上城区') {
        this.activeName = 'third'
        this.preList()
      } else {
        this.activeName = 'second'
        this.dynamicList()
      }

      this.behaviorList()

    },
    // 动态分析列表
    dynamicList () {
      this.dynamicloading = true
      const params = this.$tools.concatParams(this.dynamicParams)
      analyseList(params).then(res => {
        console.log(res)
        const { data } = res.data
        this.dynamicTotal = data.total
        this.dynamicData = data.detectionVOList
        // this.dynamicTotal = list.total
        // this.dynamicData = list.detectionVOList
        this.dynamicloading = false
      }).catch(() => {
        this.dynamicloading = false;
      })
    },

    goVideo (val) {
      if (val.valid === 0) {
        this.$message.error('此时间段无视频')
      }
      if (val.valid === -1) {
        this.$message.error('此时间段无视频')
      }
      if (val.valid === 1) {
        // window.location.href = "/app/video"
        this.$refs.video.init(val.videoAddress, val.siteName, val.roomName)
      }

    },
    // 医废列表
    behaviorList (val) {
      if (val) {
        if (this.behaviorLimit !== val.limit) {
          this.behaviorParams.pageNum = 1
          this.behaviorLimit = val.limit
        }
      }
      // console.log(val)
      const params = this.$tools.concatParams(this.behaviorParams)
      this.behaviorloading = true
      analyseList(params).then(res => {
        const { data } = res.data
        this.behaviorTotal = data.total
        this.behaviorData = data.detectionVOList
        this.behaviorloading = false
      }).catch(() => {
        this.behaviorloading = false;
      })
    },
    // 预检分诊列表
    async preList () {
      const res = await getPreList()
      this.preTotalList = res.data.data.inspectionList
      this.precheckTotal = res.data.data.total
      this.prePage(this.preTotalList.filter(el => el.alarmSum > 0))
      // console.log('res :>> ', res);
    },
    // 预检分诊分页
    prePage (list) {
      // console.log(val)
      this.precheckData = []
      var start = (this.precheckParams.pageNum - 1) * this.precheckParams.pageSize
      var end = ''
      if (this.precheckParams.pageNum * this.precheckParams.pageSize > this.precheckTotal) {
        end = this.precheckTotal
      }
      else {
        end = this.precheckParams.pageSize * this.precheckParams.pageNum
      }

      for (let i = start; i < end; i++) {
        this.precheckData.push(list[i])
      }
    },
    // 预检分诊按钮
    changeTab (val) {
      this.precheckParams.pageNum = 1
      this.precheckParams.pageSize = 10
      if (val === 'all') {
        this.prePage(this.preTotalList)
      } else {
        this.prePage(this.preTotalList.filter(el => el.alarmSum > 0))
      }
    },
    // 医废告警画面详情
    goDetail (val) {
      // console.log(val)
      const params = {
        siteName: val.siteName,
        roomName: val.roomName,
        roomId: val.roomId,
        date: this.$moment(val.date).format('YYYY-MM-DD')
      }
      if (val.gmtLastAlarm) {
        this.$router.push({
          path: '/warning/detail',
          query: {
            info: this.$Base64.encode(JSON.stringify(params))
          }
        })
      } else {
        this.$message.error(`${val.siteName}--${val.roomName}最近无告警记录`)
      }
      // this.$router.push('/warning/detail')
    },
    // 预检分诊告警画面
    goAlarm (val) {
      // console.log('val :>> ', val);
      const params = {
        unitName: val.unitName,
        roomName: val.roomName,
        list: val.list
      }
      if (val.lastAlarmTime) {
        this.$router.push({
          path: '/warning/predetail',
          query: {
            info: this.$Base64.encode(JSON.stringify(params))
          }
        })
      } else {
        this.$message.error(`${val.unitName}--${val.roomName}最近无告警记录`)
      }
    },
    queryForm (val) {
      // console.log(val)
      this.behaviorParams = {
        ...val,
        pageNum: 1,
        pageSize: 10,
        type: this.behaviorParams.type,
      }
      this.behaviorList()
    },
    dynamicForm (val) {
      // console.log(val)
      this.dynamicParams = {
        ...val,
        pageNum: 1,
        pageSize: 10,
        type: this.dynamicParams.type,
      }
      this.dynamicList()
    },
    // 预检分诊
    precheckQueryForm (val) {
      console.log('val :>> ', val);
    },
    precheckList () {

    },
    // 查看工作时长页面
    workingHours (val) {
      // console.log(val)
      this.$refs.working.init(val.timePiarVO, val.siteName, val.roomName)
    },
    // 表格居中
    headClass () {
      return "text-align:center"
    },
    rowClass () {
      return "text-align:center"
    },
    // 下载图片
    download (val) {
      imageExist(val.id).then(res => {
        if (res.data.data.ifExist === true) {
          window.open(`${this.baseUrl}/download/image?token=${this.token}&pictureAddress=${res.data.data.pictureAddress}&zipAddress=${res.data.data.zipAddress}`, '_self')
        } else {
          this.$message.error('暂无图片')
        }
      })
    }
  }
};
</script>

<style scoped lang="scss">
.recognition {
  margin-top: 10px;
  position: relative;
  ::v-deep .el-tabs {
    // .tablebox {
    //   max-height: 100px;
    // }
    // cursor: pointer;
    .el-tabs__item {
      color: rgb(255, 255, 255);
    }
    .is-active {
      color: #409eff;
    }
    .el-tabs__nav-wrap {
      padding: 0 40px;
    }
    .el-tabs__content {
      // padding-left: 30px;
      margin-left: 30px;
      padding: 20px 20px 10px;
      height: 78vh;
      background: url("../../assets/body.png") no-repeat;
      background-size: 100% 100%;
      position: relative;
      h3 {
        color: #30c6fe;
        font-size: 20px;
        font-weight: bold;
      }
      .btn {
        position: absolute;
        right: 5%;
      }
      .selectbox {
        display: flex;
        align-items: center;
        margin: 15px 0;
        > div {
          display: flex;
          align-items: center;
          margin-right: 10px;
          p {
            color: white;
            font-size: 14px;
          }
          .el-input__inner {
            height: 30px;
            line-height: 1;
          }
          .el-select {
            .el-input__suffix {
              top: 5px;
            }
          }

          .el-date-editor .el-range-separator,
          .el-date-editor .el-input__icon {
            margin-top: -10px;
          }
        }
      }
    }
  }
  ::v-deep .filter-box {
    margin: 0;
  }
  ::v-deep .filter-wrapper {
    background: none;
    padding: 0;
    .el-form-item__label {
      color: #fff;
    }
  }
  ::v-deep .el-table {
    background: none;
  }
  ::v-deep .table-wrapper {
    background: none;
    height: 62vh;
    // max-height: 62vh;
    padding: 0;
    .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
      background: #174e92;
    }
  }
  .basepagination {
    margin-top: 10px;
    text-align: right;
  }
  .precheckmyTable {
    margin-top: 50px;
  }
}
</style>
